import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { Loading } from '../components/shared/Loading';
import { ClaimPraiseInner } from '../components/claim-praise/ClaimPraiseInner';
import { claimPraise, getPraiseById } from '../api/praise.service';
import { Praise } from '../types/praise.types';

const ClaimPraise = () => {
  const { id = '' } = useParams();
  const [data, setData] = useState<Praise | null>(null);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  
  const queryParams = new URLSearchParams(location.search);
  const previewParam = queryParams.get('preview');
  const preview = previewParam === 'true' ? true : false;

  useEffect(() => {
    document.title = 'MoP Buddy | Kudos';
    const loadData = async () => {
      if (id) {
        setLoading(true);
        try {
          const data = await getPraiseById(id);
          setData(data);
        } catch (error) {
          console.log('Error fetching praise', error);
        } finally {
          setLoading(false);
        }
      }
    };

    loadData();
  }, [id]);

  if (loading) {
    return <Loading size="medium" />;
  }

  if (!data) {
    return <div>Error</div>;
  }

  const handleClaim = async () => {
    // Skip if already claimed
    if (data.claimed) {
      localStorage.setItem(`claimTime_${id}`, new Date(data.updatedAt).toISOString());
      return;
    }

    try {
      const claimTime = new Date();
      localStorage.setItem(`claimTime_${id}`, claimTime.toISOString());

      const response = await claimPraise(data.id);
      setData((prev) => (prev ? { ...prev, claimed: response.claimed } : null));
    } catch (error) {
      console.error('Error claiming praise');
      return;
    }
  };

  return <ClaimPraiseInner praiseId={id} praise={data} onClaim={handleClaim} preview={preview}/>;
};

export default ClaimPraise;
