interface LoadingProps {
  size?: LoadingSize;
}

type LoadingSize = 'small' | 'medium' | 'large';

const loadingSize: Record<LoadingSize, string> = {
  small: 'w-4 h-4',
  medium: 'w-8 h-8',
  large: 'w-12 h-12',
};

export const Loading = (props: LoadingProps) => {
  const { size = 'medium' } = props;

  return (
    <div className="flex items-center justify-center h-screen">
      <div
        className={`${loadingSize[size]} border-2 border-gray-300 rounded-full animate-spin border-t-primary`}
      />
    </div>
  );
};
