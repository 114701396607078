import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

export const getPraiseById = async (id: string) => {
  const response = await axios.get(`${apiUrl}/feedback/praise/${id}`);
  return response.data;
};

export const claimPraise = async (id: string) => {
  const response = await axios.post(`${apiUrl}/feedback/praise/${id}/claim`);
  return response.data;
};
