import { useCallback, useRef, useState } from 'react';
import { useLongPress } from 'use-long-press';

import { User } from '../../types/praise.types';

interface LetterItemProps {
  handleLongPress: () => void;
  user: User;
}

export const LetterItem = (props: LetterItemProps) => {
  const { handleLongPress, user } = props;
  const [int, setInt] = useState<NodeJS.Timer | null | any>(null);

  const callback = useCallback(() => {
    handleLongPress();
  }, []);

  const btnRef = useRef<HTMLDivElement>(null);

  const handleStartLongPress = () => {
    let progress = 0;
    const interval = setInterval(() => {
      progress += 1;
      if (progress <= 100 && btnRef?.current?.style) {
        btnRef.current.style.background = `linear-gradient(90deg, #FDD745 ${progress}%, white ${progress}%)`;
      }
    }, 5.8);

    // Store interval ID in state to clear it later
    setInt(interval);
  };

  const handleCancelLongPress = () => {
    if (btnRef?.current?.style)
      btnRef.current.style.background =
        'linear-gradient(to right, white, white)'; // Reset background color
    int && clearInterval(int);
  };

  const bind = useLongPress(callback, {
    onStart: () => handleStartLongPress(),
    onCancel: () => handleCancelLongPress(),
    filterEvents: () => true, // All events can potentially trigger long press (same as 'undefined')
    threshold: 730, // In milliseconds
    cancelOnMovement: 0, // Square side size (in pixels) inside which movement won't cancel long press
    cancelOutsideElement: true, // Cancel long press when moved mouse / pointer outside element while pressing
  });

  return (
    <div
      className={`px-4 overflow-hidden bg-no-repeat bg-contain cursor-pointer w-full h-full bg-[#151D2C] bg-letter rounded-xl select-none touch-none`}
      onContextMenu={(e) => e.preventDefault()}
      {...bind()}
    >
      <div className="flex flex-col items-center gap-[55px] max-[630px]:gap-[40px]">
        <div className="text-white mt-[370px] max-[360px]:mt-[300px] ">
          <div className="font-semibold text-[40px] md:leading-[48px] max-[360px]:leading-[40px] max-[360px]:text-[32px]">
            <div className="flex justify-center">
              <p className="font-bold">CONGRATS</p>
            </div>
            <div className="flex justify-center">
              <p className="font-bold">{user.firstName.toUpperCase()}</p>
            </div>
          </div>
        </div>

        <div
          ref={btnRef}
          className={`relative w-full text-center rounded-full h-14 max-[360px]:h-13 overflow-hidden border-white-600 bg-white border-white-600`}
        >
          <div className="absolute inset-0 flex justify-center mt-[13px] text-[18px]">
            <span className="items-center">Hold to Claim</span>
          </div>
        </div>
      </div>
    </div>
  );
};
