import { useEffect, useState } from 'react';
import Lottie from 'lottie-react';

import { Praise } from '../../types/praise.types';
import { LetterItem } from './LetterItem';
import { PraiseDetails } from './PraiseDetails';

import confetti from '../../assets/lottie/confetti.json';

export const ClaimPraiseInner = ({
  praiseId,
  praise,
  onClaim,
  preview
}: {
  praiseId: string;
  praise: Praise;
  onClaim: () => void;
  preview?: boolean;
}) => {
  
  const [showAnimation, setShowAnimation] = useState(false);

  const imageDimensions =
    'w-[300px] h-[600px] min-[320px]:w-[300px] min-[320px]:h-[500px] min-[375px]:w-[365px] min-[375px]:h-[600px] min-[420px]:w-[360px] min-[420px]:h-[600px] sm:w-[620px] sm:h-[265px] md:h-[600px] md:w-[360px]';

  useEffect(() => {
    if (showAnimation) {
      setTimeout(() => {
        setShowAnimation(false);
      }, 2000);
    }
  }, [showAnimation]);

  const handleClaim = async () => {
    setShowAnimation(true);
    setPraiseIdInLocalStorage(praiseId, true);
    onClaim();
  };

  const getValueColor = (companyValue: string) => {
    switch (companyValue) {
      case 'ValueDeliveryBees':
        return 'bg-[#F04A53]';
      case 'DataNerds':
        return 'bg-[#4880ED]';
      case 'TickingLikeClock':
        return 'bg-[#9A5DE7]';
      case 'JunkiesForImprovement':
        return 'bg-[#68B744]';
      default:
        return 'bg-[#F07C4A]';
    }
  };

  // Waiting for the response from the server to show the details takes too long
  const isPraiseVisible = getIsPraiseVisible(praiseId);
  
  const claimTimeString = localStorage.getItem(`claimTime_${praiseId}`);
  const claimTime = claimTimeString ? new Date(claimTimeString) : null;
  const date = claimTime ? claimTime.toLocaleDateString('en-GB', { timeZone: 'Europe/Sarajevo', day: '2-digit', month: '2-digit', year: 'numeric' }) : '';
  const time = claimTime ? claimTime.toLocaleTimeString('en-GB', { timeZone: 'Europe/Sarajevo', hour: '2-digit', minute: '2-digit' }) : '';
  const formattedClaimTime = `${date.split('/').join('-')} ${time}`; 
  
  const valueColor = getValueColor(praise.companyValue);
  
  return (
    <div className="flex flex-col sm:items-center justify-center px-3 h-[80vh] sm:h-screen gap-2 narrow-margin-top">
      <div className="relative flex justify-center mt-16 rounded-lg sm:items-center md:px-0 sm:mt-0"> 
        {showAnimation && (
          <div className="absolute z-10 h-full sm:w-375 md:w-600 md:h-600 -top-10 md:-top-44">
            <Lottie animationData={confetti} loop={true} />
          </div>
        )}
  
        <div
          className={`fixed w-full ${(!isPraiseVisible && !preview) ? 'invisible' : ''} ${imageDimensions}`}
        >
          <div className={`flex justify-center w-full h-full`}>
            <PraiseDetails praise={praise} />
          </div>
        </div>
        <div className={`${(isPraiseVisible || preview) && 'invisible'} ${imageDimensions}`}>
          <LetterItem handleLongPress={handleClaim} user={praise.receiver} />
        </div>
      </div>
      
      <div className={`${(!claimTime) ? 'invisible' : ''} flex items-center justify-center`}>
        <div className={`px-3 py-1 text-[14px] text-white ${valueColor} rounded-full bg-opacity-90`}>
          Claimed at {formattedClaimTime}
        </div>
      </div>
    </div>
  );
};

const setPraiseIdInLocalStorage = (praiseId: string, praiseClaimed: boolean) => {
  const praiseStatus = JSON.parse(localStorage.getItem('praiseStatus') || '{}');
  praiseStatus[praiseId] = praiseClaimed;
  localStorage.setItem('praiseStatus', JSON.stringify(praiseStatus));
}

const getIsPraiseVisible = (praiseId: string) => {
  const praiseStatus = JSON.parse(localStorage.getItem('praiseStatus') || '{}');
  return praiseStatus[praiseId];
}