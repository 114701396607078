import bg404 from '../../assets/images/404bg.png';

export const EmptyScreen = () => {
  return (
    <div className="flex flex-col items-center self-center justify-center w-full h-screen max-w-xs gap-5 m-auto">
      <img src={bg404} alt="404" />
      <div className="text-xl text-dark-text">Oops, nothing here...</div>
    </div>
  );
};
