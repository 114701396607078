import { Praise } from '../../types/praise.types';

interface PraiseDetailsProps {
  praise: Praise;
}

const getBgImage = (companyValue: string) => {
  switch (companyValue) {
    case 'ValueDeliveryBees':
      return 'bg-value-delivery-bee';
    case 'DataNerds':
      return 'bg-data-nerd';
    case 'TickingLikeClock':
      return 'bg-ticking-like-clock';
    case 'JunkiesForImprovement':
      return 'bg-junkies-for-improvement';
    default:
      return 'bg-happy-fools';
  }
};

const getBorderColor = (companyValue: string) => {
  switch (companyValue) {
    case 'ValueDeliveryBees':
      return 'border-value-delivery-bee';
    case 'DataNerds':
      return 'border-data-nerd';
    case 'TickingLikeClock':
      return 'border-ticking-like-clock';
    case 'JunkiesForImprovement':
      return 'border-junkies-for-improvement';
    default:
      return 'border-happy-fools';
  }
};

export const PraiseDetails = (props: PraiseDetailsProps) => {
  const { praise } = props;

  const bgImage = getBgImage(praise.companyValue);
  const borderColor = getBorderColor(praise.companyValue);

  return (
    <div
      className={`${bgImage} bg-no-repeat bg-contain w-full h-full rounded-xl overflow-hidden`}
    >
      <div className="flex flex-col gap-1">
        <div className="flex items-center justify-center w-full mt-[66px] max-[360px]:mt-[55px]">
          <div className={`h-[200px] w-[200px] max-[360px]:w-[150px] max-[360px]:h-[150px] max-[360px]:border-[10px] border-[15px] rounded-full ${borderColor}`}>
            {praise.receiver.avatarUrl ? (
              <img
                src={praise.receiver.avatarUrl}
                alt="avatar"
                className="rounded-full"
              />
            ) : (
              <div className="rounded-full bg-gray-card"></div>
            )}
          </div>
        </div>
      
        <div className="text-[28px] leading-[33.6px] px-[16px] max-[360px]:text-[20px] max-[360px]:leading-[28px] w-full text-bold text-white">
          <div className='flex justify-center'>
            <p className="font-bold">{praise.receiver.firstName.toUpperCase()}</p>
          </div>
          <div className="flex justify-center mt-[5px] max-[360px]:mt-0">
            <p className="font-bold">{praise.receiver.lastName.toUpperCase()}</p>
          </div>
        </div>
      
        <div className="w-full mt-[5px]">
          <div className='flex justify-center'>
            <div className="flex items-center max-[360px]:text-[14px] px-5 py-2 space-x-1 text-white bg-black rounded-full bg-opacity-15">
              <p>From</p><p className="font-bold ">{praise.sender.firstName} {praise.sender.lastName}</p>
            </div>
          </div>
        </div>
      
        <div className="p-[16px] mt-[20px] text-lg max-[360px]:text-[15px] max-[360px]:mt-0 max-[360px]:p-[12px] leading-[25.2px] text-white break-words">
          {praise.message}
        </div>
      </div>
    </div>
  );
};
