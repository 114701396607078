import { RouteObject } from 'react-router-dom';

import App from './App';
import ClaimPraise from './routes/claim-kudos';
import { EmptyScreen } from './components/404/EmptyScreen';
import Login from './components/login';
import Employees from './components/employees';

const routes: RouteObject[] = [
  {
    path: '*',
    element: <EmptyScreen />,
  },
  {
    path: '/',
    element: <App />,
  },
  {
    path: '/claim-kudos/:id',
    element: <ClaimPraise />,
  },
  // {
  //   path: '/login',
  //   element: <Login />,
  // },
  // {
  //   path: '/employees',
  //   element: <Employees />,
  // },
];

export default routes;
